<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Nowa grupa -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowa grupa</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa grupy
                  </label>
                  <input
                    v-model="group.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <p v-if="group.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ group.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postTaskGroup()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Edycja grupy -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-0" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Edycja grupy</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa grupy
                  </label>
                  <input
                    v-model="group.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :disabled="group.privacy"
                  />
                </div>
              </div>
            </div>

            <h5 class="mt-3">Udostępnianie grupy</h5>

            <div class="row mb-3">
              <div class="col-12">
                <SimpleTypeahead
                  ref="membersRef"
                  :items="groupMembersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectMember"
                ></SimpleTypeahead>
              </div>
            </div>

            <div v-if="group.members.length > 0" class="d-flex align-items-center mt-3" style="flex-wrap: wrap">
              <material-badge
                v-for="item in group.members"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ item.fullName }}
                <i
                  class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer"
                  @click="groupMembers.push(item); groupMembersAC.push(item.fullName); group.members.splice(index, 1)"
                >
                  close
                </i>
              </material-badge>
            </div>

            <p v-if="group.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ group.error }}</p>

            <div class="d-flex align-items-center mt-3" :class="group.privacy ? 'justify-content-end' : 'justify-content-between'">
              <span v-if="!group.privacy" class="text-danger my-auto text-sm cursor-pointer" @click="deleteGroup(group.id)">
                Usuń grupę
              </span>

              <div class="d-flex align-items-center">
                <material-button
                  :color="color"
                  variant="gradient"
                  @click="patchTaskGroup(group.id)"
                >
                  Zapisz
                </material-button>

                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="closeModal()"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nowy kontrahent -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowy kontrahent</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 pb-3 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="createdCustomer.type === 'Company'"
                    @change="createdCustomer.type = 'Company'"
                  />
                  <span class="text-sm mt-1">Przedsiębiorstwo</span>
                </label>
              </div>
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="createdCustomer.type === 'Person'"
                    @change="createdCustomer.type = 'Person'"
                  />
                  <span class="text-sm mt-1">Osoba fizyczna</span>
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ createdCustomer.type === "Company" ? 'Nazwa wyświetlana' : 'Imię i nazwisko' }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="createdCustomer.displayName"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center form-label ms-0">
                  Grupy <i class="material-icons text-warning text-sm ms-1">star</i>
                </label>
                <label v-for="item in createdCustomer.groups" :key="item" class="d-flex align-items-center mx-0">
                  <material-checkbox class="me-1" :checked="item.status" @change="item.status = !item.status" /> {{ item.name }}
                </label>
              </div>
            </div>

            <p v-if="createdCustomer.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ createdCustomer.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postNewCustomer()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nowe podzadanie -->
    <div v-if="modals[3]" class="modal-dialog">
      <div class="modal-content">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowe podzadanie</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 pb-3 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa podzadania
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="subtask.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Status
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="subtask.status"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="todo">Do zrobienia</option>
                  <option value="done">Zrobione</option>
                  <option value="late">Spóźnione</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Osoba odpowiedzialna
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <SimpleTypeahead
                  ref="usersRef"
                  :items="subtaskUsersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectSubtaskUser"
                ></SimpleTypeahead>
              </div>

              <div class="d-flex mt-2">
                <material-badge
                  v-if="subtask.member"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ subtask.member.fullName }}
                </material-badge>
              </div>
            </div>

            <p v-if="subtask.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ subtask.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postSubtask()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edycja podzadania -->
    <div v-if="modals[4]" class="modal-dialog">
      <div class="modal-content">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Edycja podzadania</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 pb-3 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa podzadania
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="subtask.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Status
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="subtask.status"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="todo">Do zrobienia</option>
                  <option value="done">Zrobione</option>
                  <option value="late">Spóźnione</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Osoba odpowiedzialna
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <SimpleTypeahead
                  ref="usersRef"
                  :items="subtaskUsersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectSubtaskUser"
                ></SimpleTypeahead>
              </div>

              <div class="d-flex mt-2">
                <material-badge
                  v-if="subtask.member"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ subtask.member.fullName }}
                </material-badge>
              </div>
            </div>

            <p v-if="subtask.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ subtask.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="patchSubtask()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <!-- Filtry -->
    <div class="d-flex justify-content-end mb-4">
      <!-- Filtr: Moje zadania -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle me-3"
        data-bs-toggle="dropdown"
      >
        Moje zadania
      </material-button>
      <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
        <li>
          <a class="dropdown-item border-radius-md cursor-pointer" @click="getNewTaskGroup()"
          >Nowa grupa</a>
        </li>
        <li v-if="groups.length !== 0">
          <hr class="horizontal bg-light my-2">
        </li>
        <li v-for="item in groups" :key="item" :class="item.owner.id === me.id ? 'd-flex align-items-center' : 'd-none'">
          <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeGroup(item.name)">
            {{ item.name }}
          </a>
          <a class="dropdown-item border-radius-md cursor-pointer" style="width: 25%" @click="getTaskGroup(item.id)">
            <i class="material-symbols-outlined text-lg d-flex align-items-center justify-content-center">edit</i>
          </a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <li>
          <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_groups = []; getTasks(page)"
          >Wyczyść filtr</a>
        </li>
      </ul>

      <!-- Filtr: Współdzielone zadania -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle me-3"
        data-bs-toggle="dropdown"
      >
        Współdzielone zadania
      </material-button>
      <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
        <li v-for="item in groups" :key="item" :class="item.owner.id !== me.id ? 'd-flex align-items-center' : 'd-none'">
          <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeSharedGroup(item.name)">
            {{ item.name }}
          </a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <li>
          <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_sharedGroups = []; getTasks(page)"
          >Wyczyść filtr</a>
        </li>
      </ul>

      <!-- Filtr: Osoba odpowiedzialna -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle me-3"
        data-bs-toggle="dropdown"
      >
        Osoba odpowiedzialna
      </material-button>
      <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
        <li v-for="item in members" :key="item" class="d-flex">
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeMember(item.fullName)">
            {{ item.fullName }}
          </a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <li>
          <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_members = []; getTasks(page)"
          >Wyczyść filtr</a>
        </li>
      </ul>

      <!-- Przycisk: Nowe zadanie -->
      <material-button
        :color="color"
        variant="gradient"
        @click="task && task.operation === 'new' ? task = null : getNewTask()"
      >
        + &nbsp; Nowe zadanie
      </material-button>
    </div>

    <div class="d-flex justify-content-end mb-4">
      <material-badge
        v-for="item in filter_groups"
        :key="item"
        color="danger"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeGroup(item)">close</i>
      </material-badge>
      
      <material-badge
        v-for="item in filter_sharedGroups"
        :key="item"
        color="warning"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeSharedGroup(item)">close</i>
      </material-badge>
      
      <material-badge
        v-for="item in filter_members"
        :key="item"
        color="success"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeMember(item)">close</i>
      </material-badge>

      <material-badge
        :color="filter_status.includes('todo') ? 'info' : 'light'"
        size="sm"
        class="d-flex align-items-center ms-2 cursor-pointer"
        @click="onChangeStatus('todo')"
      >
        Do zrobienia
      </material-badge>

      <material-badge
        :color="filter_status.includes('late') ? 'info' : 'light'"
        size="sm"
        class="d-flex align-items-center ms-2 cursor-pointer"
        @click="onChangeStatus('late')"
      >
        Spóźnione
      </material-badge>

      <material-badge
        :color="filter_status.includes('done') ? 'info' : 'light'"
        size="sm"
        class="d-flex align-items-center ms-2 cursor-pointer"
        @click="onChangeStatus('done')"
      >
        Zrobione
      </material-badge>
    </div>

    <div class="row">
      <div class="mb-4" :class="task ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Zadania</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="filter_name"
                      v-model="filter_name"
                      :items="tasksAC"
                      :min-input-length="1"
                      class="text-sm px-3 py-2 border-secondary"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      @select-item="selectItem"
                    ></SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <td class="text-center" style="width: 5%">
                          <material-checkbox class="mx-auto" @change="onChangeTasksStatus($event.target.checked)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 30%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 30%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('member.fullName')">Osoba odpowiedzialna</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('closedAt')">Termin</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('status')">Status</a>
                        </th>

                        <th style="width: 5%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="item in tasks"
                        :key="item"
                      >
                        <tr :class="task && task.id === item.id ? isDarkMode ? 'bg-dark' : 'bg-light' : null">
                          <!-- Tabela: Checkbox -->
                          <td class="py-1 text-center">
                            <material-checkbox class="mx-auto" :checked="item.status === 'done'" :disabled="item.status === 'done'" @change="onChangeTaskStatus(item.id)" />
                          </td>

                          <!-- Tabela: Nazwa -->
                          <td class="ps-0 py-1 cursor-pointer" @click="task && task.id === item.id ? task = null : getTask(item.id)">
                            <p class="m-0 font-weight-bold text-xs" :class="item.status === 'done' ? 'text-decoration-line-through' : ''" :style="item.status === 'late' ? 'color: red !important' : ''">{{ item.name }}</p>
                          </td>

                          <!-- Tabela: Osoba odpowiedzialna -->
                          <td class="ps-0 py-1 cursor-pointer" @click="task && task.id === item.id ? task = null : getTask(item.id)">
                            <p class="m-0 text-xs" :class="item.status === 'done' ? 'text-decoration-line-through' : ''" :style="item.status === 'late' ? 'color: red !important' : ''">{{ item.member ? item.member.fullName : "Brak" }}</p>
                          </td>

                          <!-- Tabela: Termin -->
                          <td class="ps-0 py-1 cursor-pointer" @click="task && task.id === item.id ? task = null : getTask(item.id)">
                            <p class="m-0 text-xs" :class="item.status === 'done' ? 'text-decoration-line-through' : ''" :style="item.status === 'late' ? 'color: red !important' : ''">{{ moment(item.closedAt).format("DD/MM/YYYY") }} <b>{{ moment(item.closedAt).format("(ddd, ") + getRelativeTime(item.closedAt) + ")" }}</b></p>
                          </td>

                          <!-- Tabela: Status -->
                          <td class="ps-0 py-1 cursor-pointer" @click="task && task.id === item.id ? task = null : getTask(item.id)">
                            <p class="m-0 text-xs" :class="item.status === 'done' ? 'text-decoration-line-through' : ''" :style="item.status === 'late' ? 'color: red !important' : ''">
                              {{ item.status === "done" ? "Zrobione" : item.status === "todo" ? "Do zrobienia" : item.status === "late" ? "Spóźnione" : "Brak" }}
                            </p>
                          </td>

                          <!-- Tabela: Menu -->
                          <td class="py-1 text-xs text-end">
                            <a
                              :class="[color]"
                              data-bs-toggle="dropdown"
                            >
                              <i class="material-icons cursor-pointer">more_vert</i>
                            </a>
                            <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                              <li class="cursor-pointer" @click="getTask(item.id)">
                                <a class="px-2 dropdown-item border-radius-md">
                                  <div class="mx-0 px-0 py-1 d-flex">
                                    <div class="d-flex flex-column justify-content-center">
                                      <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                        Podgląd
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li v-if="(item.createdBy && item.createdBy.id == me.id) || item.member.id == me.id" class="cursor-pointer" @click="getTask(item.id, 'edit')">
                                <a class="px-2 dropdown-item border-radius-md">
                                  <div class="mx-0 px-0 py-1 d-flex">
                                    <div class="d-flex flex-column justify-content-center">
                                      <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                        Edycja
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <hr class="horizontal my-1 bg-light">
                              <li class="cursor-pointer" @click="$store.state.createdEvent.operation = true; $store.state.createdEvent.task = { id: item.id, name: item.name }; $store.state.createdEvent.attachments = []; $redirect('Kalendarz')">
                                <a class="px-2 dropdown-item border-radius-md">
                                  <div class="mx-0 px-0 py-1 d-flex">
                                    <div class="d-flex flex-column justify-content-center">
                                      <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                        Dodaj nowe wydarzenie
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li class="cursor-pointer" @click="$store.state.createdSale.operation = true; $store.state.createdSale.task = { id: item.id, name: item.name }; $store.state.createdSale.attachments = []; $redirect('Sprzedaż')">
                                <a class="px-2 dropdown-item border-radius-md">
                                  <div class="mx-0 px-0 py-1 d-flex">
                                    <div class="d-flex flex-column justify-content-center">
                                      <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important"> 
                                        Dodaj nową sprzedaż
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <hr class="horizontal my-1 bg-light">
                              <li class="cursor-pointer" @click="deleteTask(item.id)">
                                <a class="px-2 dropdown-item border-radius-md">
                                  <div class="mx-0 px-0 py-1 d-flex">
                                    <div class="d-flex flex-column justify-content-center">
                                      <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                        Usuń
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <template
                          v-for="element in item.subtasks"
                          :key="element"
                        >
                          <tr>
                            <td class="py-2 text-xs text-center">-</td>
                            <td class="ps-0 py-2">
                              <p class="m-0 text-xs">{{ element.name ? element.name : "Brak" }}</p>
                            </td>
                            <td class="ps-0 py-2">
                              <p class="m-0 text-xs">{{ element.member ? element.member.fullName : "Brak" }}</p>
                            </td>
                            <td class="ps-0 py-2 text-xs">-</td>
                            <td class="ps-0 py-2">
                              <p class="m-0 text-xs">{{ element.status === "done" ? "Zrobione" : element.status === "todo" ? "Do zrobienia" : element.status === "late" ? "Spóźnione" : "Brak" }}</p>
                            </td>
                            <td class="ps-0 py-2 text-xs"></td>
                          </tr>
                        </template>
                      </template>

                      <!-- Brak zadań -->
                      <tr :class="tasks.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="6">Nie znaleziono zadań</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="tasks.length !== 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>
                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Nowe zadanie -->
      <div v-if="task && task.operation === 'new'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div v-if="task.sale" class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiązana sprzedaż: <span class="font-weight-normal ms-2">{{ task.sale.name }}</span>
                </label>
              </div>
            </div>

            <div v-if="task.event" class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiązane wydarzenie: <span class="font-weight-normal ms-2">{{ task.event.title }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa zadania
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="task.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 mb-1">
                <label class="d-flex form-label ms-0">
                  Termin
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <input
                  v-model="task.closedAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>

              <div class="d-flex align-items-center" style="flex-wrap: wrap">
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = moment().format('yyyy-MM-DD')">D</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = moment().add(1, 'days').format('yyyy-MM-DD')">J</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = findNextFriday()">KT</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = moment().add(7, 'days').format('yyyy-MM-DD')">1T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = moment().add(14, 'days').format('yyyy-MM-DD')">2T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center cursor-pointer" @click="task.closedAt = moment().add(1, 'months').format('yyyy-MM-DD')">1M</material-badge>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Status
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="task.status"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="todo">Do zrobienia</option>
                  <option value="done">Zrobione</option>
                  <option value="late">Spóźnione</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Osoba odpowiedzialna
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <SimpleTypeahead
                  ref="usersRef"
                  :items="usersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectUser"
                ></SimpleTypeahead>
              </div>
              
              <div class="d-flex mt-2">
                <material-badge
                  v-if="task.member"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ task.member.fullName }}
                </material-badge>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Kontrahent
                  <i class="material-icons text-sm ms-1 cursor-pointer" :class="`text-${ color }`" @click="getNewCustomer()">add_circle</i>
                </label>
                <SimpleTypeahead
                  ref="customersRef"
                  :items="customersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectCustomer"
                ></SimpleTypeahead>
              </div>

              <div class="d-flex mt-2">
                <material-badge
                  v-if="task.customer"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ task.customer.displayName }}
                </material-badge>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Pozostałe</h5>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Grupa
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="task.group"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in me.taskGroups" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-sm">
                <label class="mb-2">
                  Notatka <span class="text-xxs">(limit znaków: 5000)</span>
                </label>
                <div class="input-group input-group-outline" >
                  <textarea
                    v-model="task.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-black'"
                    style="height: 250px; resize: none"
                    rows="5"
                    maxlength="5000"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                <label class="m-0">Pola <span class="text-xxs">(limit znaków: 255)</span></label>
              </div>
            </div>

            <div v-for="(item, index) in task.inputs" :key="item" class="row mb-3">
              <div style="width: 30%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div style="width: 60%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="text-end my-auto" style="width: 10%">
                <i class="material-icons text-danger cursor-pointer" @click="task.inputs.splice(index, 1)">cancel</i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="task.inputs.push({ name: '', value: '', type: 'text' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj nowe pole</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Załączniki</h5>

            <div v-for="(item, index) in task.attachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <span class="text-sm me-2">{{ item.file.name }}</span> ({{ item.file.size }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="task.attachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj załącznik</span>
                  <input type="file" class="d-none" @change="createAttachment(task.attachments, $event)">
                </label>
              </div>
            </div>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPostTask()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="task = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edycja zadania -->
      <div v-if="task && task.operation === 'edit'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa zadania
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="task.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 mb-1">
                <label class="d-flex form-label ms-0">
                  Termin
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <input
                  v-model="task.closedAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>

              <div class="d-flex align-items-center" style="flex-wrap: wrap">
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = moment().format('yyyy-MM-DD')">D</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = moment().add(1, 'days').format('yyyy-MM-DD')">J</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = findNextFriday()">KT</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = moment().add(7, 'days').format('yyyy-MM-DD')">1T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="task.closedAt = moment().add(14, 'days').format('yyyy-MM-DD')">2T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center cursor-pointer" @click="task.closedAt = moment().add(1, 'months').format('yyyy-MM-DD')">1M</material-badge>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Status
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="task.status"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="todo">Do zrobienia</option>
                  <option value="done">Zrobione</option>
                  <option value="late">Spóźnione</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Osoba odpowiedzialna
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <SimpleTypeahead
                  ref="usersRef"
                  :items="usersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectUser"
                ></SimpleTypeahead>
              </div>
              
              <div class="d-flex mt-2">
                <material-badge
                  v-if="task.member"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ task.member.fullName }}
                </material-badge>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Kontrahent
                  <i class="material-icons text-sm ms-1 cursor-pointer" :class="`text-${ color }`" @click="getNewCustomer()">add_circle</i>
                </label>
                <SimpleTypeahead
                  ref="customersRef"
                  :items="customersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectCustomer"
                ></SimpleTypeahead>
              </div>
              
              <div class="d-flex mt-2">
                <material-badge
                  v-if="task.customer"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ task.customer.displayName }}
                </material-badge>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Pozostałe</h5>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Grupa
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="task.group"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in me.taskGroups" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-sm">
                <label class="mb-2">
                  Notatka <span class="text-xxs">(limit znaków: 5000)</span>
                </label>
                <div class="input-group input-group-outline" >
                  <textarea
                    v-model="task.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-black'"
                    style="height: 250px; resize: none"
                    rows="5"
                    maxlength="5000"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                <label class="m-0">Pola <span class="text-xxs">(limit znaków: 255)</span></label>
              </div>
            </div>
            
            <div v-for="(item, index) in task.inputs" :key="item" class="row mb-3">
              <div style="width: 30%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div style="width: 60%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="text-end my-auto" style="width: 10%">
                <i class="material-icons text-danger cursor-pointer" @click="task.removedInputs.push(item.id); task.inputs.splice(index, 1)">cancel</i>
              </div>
            </div>

            <div v-for="(item, index) in task.newInputs" :key="item" class="row mb-3">
              <div style="width: 30%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div style="width: 60%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="text-end my-auto" style="width: 10%">
                <i class="material-icons text-danger cursor-pointer" @click="task.newInputs.splice(index, 1)">cancel</i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="task.newInputs.push({ name: '', value: '', type: 'text' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj nowe pole</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Załączniki</h5>

            <div v-for="(item, index) in task.attachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <a :href="'data:' + item.fileType + ';base64,' + item.fileBlob" class="me-2 text-sm" :download="item.fileName">{{ item.fileName }}</a> ({{ item.fileSize }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="sale.removedAttachments.push(item.id); sale.attachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in task.newAttachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <span class="text-sm me-2">{{ item.file.name }}</span> ({{ item.file.size }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="task.newAttachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj załącznik</span>
                  <input type="file" class="d-none" @change="createAttachment(task.newAttachments, $event)">
                </label>
              </div>
            </div>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPatchTask()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="task = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Podgląd zadania -->
      <div v-if="task && task.operation === 'overview'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>{{ task.name }}</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Termin: <span class="font-weight-normal ms-2">{{ moment(task.closedAt).format("DD/MM/YYYY") }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Status: 
                  <span class="font-weight-normal ms-2">
                    {{ task.status === "done" ? "Zrobione" : task.status === "todo" ? "Do zrobienia" : task.status === "late" ? "Spóźnione" : "Brak" }}
                  </span>
                </label>
              </div>
            </div>

            <div v-if="(task.createdBy && task.createdBy.id == me.id) || task.member.id == me.id" class="d-flex align-items-center my-3">
              <material-button
                v-if="task.status === 'todo' || task.status === 'late'"
                class="me-3"
                :color="color"
                variant="gradient"
                @click="onChangeTaskStatus(task.id); task.status = 'done'"
              >
                Wykonane
              </material-button>

              <material-button
                v-if="task.member.id == me.id"
                :color="color"
                variant="gradient"
                @click="getTask(task.id, 'edit')"
              >
                Edycja
              </material-button>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Kontrahent: <span class="font-weight-normal ms-2 cursor-pointer" @click="$store.state.openedCustomer = task.customer.id; $redirect('Kontrahenci')">{{ task.customer ? task.customer.displayName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Osoby odpowiedzialne: <span class="font-weight-normal ms-2">{{ task.member ? task.member.fullName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Stworzony przez: <span class="font-weight-normal ms-2">{{ task.createdBy ? task.createdBy.fullName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3">Podzadania</h5>

            <div v-for="item in task.subtasks" :key="item" class="row mb-3 cursor-pointer">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <material-checkbox
                    class="me-2"
                    :checked="item.status == 'done'"
                    @change="onChangeSubtaskStatus(item.id, $event.target.checked)"
                  />
                  <div class="h-100" @click="getSubtask(item.id)">
                    <p class="text-sm mb-1" :class="item.status == 'late' ? 'text-danger' : ''">{{ item.name }}</p>
                    <p class="mb-0 text-xs" :class="item.status == 'late' ? 'text-danger' : ''">{{ item.member.fullName }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center cursor-pointer" @click="getNewSubtask(task.id)">
                  <i class="material-symbols-outlined ms-1 me-2 text-lg">add_task</i>
                  <span class="text-sm font-weight-light">Utwórz nowe podzadanie</span>
                </div>
              </div>
            </div>

            <h5 class="mt-3">Pozostałe</h5>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Grupa: <span class="font-weight-normal ms-2">{{ task.group ? task.group.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiązana sprzedaż: 
                  <span v-if="task.sales.length === 0" class="font-weight-normal ms-2 cursor-pointer">Brak</span>
                  <span
                    v-for="(item, index) in task.sales"
                    v-else
                    :key="item"
                    class="font-weight-normal ms-2 cursor-pointer"
                    @click="$store.state.openedSale = item.id; $redirect('Sprzedaż')"
                  >
                    {{ item.name }}
                    <span v-if="index < task.sales.length - 1">, </span>
                  </span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiązane wydarzenia: 
                  <span v-if="task.events.length === 0" class="font-weight-normal ms-2 cursor-pointer">Brak</span>
                  <span
                    v-for="(item, index) in task.events"
                    v-else
                    :key="item"
                    class="font-weight-normal ms-2 cursor-pointer"
                    @click="$store.state.openedEvent = item.id; $redirect('Kalendarz')"
                  >
                    {{ item.title }}
                    <span v-if="index < task.events.length - 1">, </span>
                  </span>
                </label>
              </div>
            </div>

            <div class="row" :class="task.inputs.length !== 0 ? 'mb-2' : ''">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Notatka: <span class="font-weight-normal ms-2">{{ task.note ? task.note : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div v-for="(item, index) in task.inputs" :key="item" class="row" :class="index !== task.inputs.length - 1 ? 'mb-2' : ''">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  {{ item.name }}: <span class="font-weight-normal ms-2">{{ item.value }}</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3">Załączniki</h5>

            <div v-if="task.attachments && task.attachments.length === 0" class="text-sm">Brak załączników</div>
            
            <div v-for="(item, index) in task.attachments" v-else :key="item" :class="index !== task.attachments.length - 1 ? `mb-3` : ``">
              <div class="row mb-1">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i>
                  <a :href="'data:' + item.fileType + ';base64,' + item.fileBlob" class="me-2 text-sm" :download="item.fileName">
                    {{ item.fileName }}
                  </a>
                  ({{ item.fileSize }} KB)
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-xs d-flex">
                  {{ item.description }}
                </div>
              </div>
            </div>

            <h5 class="mt-3">Aktywność</h5>

            <div class="d-flex mb-3">
              <label class="form-label ms-0 me-3 my-auto">Pokaż</label>
              <select
                v-model="task.activity"
                class="p-1 text-sm"
                :class="isDarkMode ? 'text-white' : 'text-dark'"
                style="border: none; border-radius: 10px"
                :style="isDarkMode ? 'background: rgb(0, 0, 0, 0.2)' : 'background: rgb(0, 0, 0, 0.1)'"
                @change="order = false"
              >
                <option value="history">Historia</option>
                <option value="comments">Komentarze</option>
              </select>

              <label class="d-flex ms-auto my-auto text-xs cursor-pointer" @click="order = !order; getTaskHistory(task.id); getTaskComments(task.id)">
                <span class="my-auto">{{ order ? 'Od najstarszych' : 'Od najnowszych' }}</span>
                <i class="material-icons my-auto">{{ order ? 'expand_more' : 'expand_less' }}</i>
              </label>
            </div>

            <!-- Historia -->
            <div v-if="task.activity === 'history'">
              <div class="row">
                <timeline-list class="bg-transparent shadow-none">
                  <TimelineItem
                    v-for="item in task.histories"
                    :key="item"
                    :full-name="item.owner.fullName"
                    :avatar="item.owner.avatar"
                    :activity="item.subject"
                    :date-time="item.createdAt"
                    :description="item.text"
                  />
                </timeline-list>
              </div>
            </div>

            <!-- Komentarze -->
            <div v-if="task.activity === 'comments'">
              <div class="row" :class="task.comments.length === 0 ? 'mb-4' : ''">
                <div class="col-12">
                  <div class="d-flex">
                    <material-avatar
                      :img="$getAvatar(me.fullName, me.avatar)"
                      alt="bruce"
                      size="sm"
                      shadow="sm"
                      circular
                    />

                    <div class="input-group input-group-outline">
                      <input
                        v-model="comment"
                        class="form-control mx-3"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                      />
                    </div>

                    <material-button
                      class="px-4"
                      :color="color"
                      variant="gradient"
                      @click="postTaskComment(task.id)"
                    >
                      +
                    </material-button>
                  </div>
                </div>
              </div>

              <hr v-if="task.comments.length != 0" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'">
              
              <div v-for="item in task.comments" :key="item" class="row mb-2">
                <div class="col-12">
                  <div class="d-flex">
                    <material-avatar
                      :img="$getAvatar(item.owner.fullName, item.owner.avatar)"
                      class="me-3"
                      alt="bruce"
                      size="sm"
                      shadow="sm"
                      circular
                    />
                    <p style="width: 100% !important">
                      <span class="text-sm d-flex">
                        <b>{{ item.owner.fullName }}</b> &nbsp;&bull; {{ item.createdAt }}
                        <i v-if="item.owner.id == me.id" class="material-icons cursor-pointer ms-auto" @click="deleteTaskComment(item.id)">delete</i>
                      </span>
                      <span class="d-block text-sm">
                        {{ item.text }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import TimelineList from "@/examples/Cards/TimelineList.vue"
import TimelineItem from "@/examples/Cards/TimelineItem.vue"
import UserDataService from "@/services/UserDataService"
import CustomerDataService from "@/services/CustomerDataService"
import TaskDataService from "@/services/TaskDataService"
import SubtaskDataService from "@/services/SubtaskDataService"
import TaskGroupDataService from "@/services/TaskGroupDataService"
import AttachmentDataService from "@/services/AttachmentDataService"
import CustomerGroupDataService from "@/services/CustomerGroupDataService"
import HistoryDataService from "@/services/HistoryDataService"
import CommentDataService from "@/services/CommentDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Tasks",
  components: {
    MaterialAvatar,
    MaterialBadge,
    MaterialCheckbox,
    MaterialRadio,
    MaterialSnackbar,
    MaterialButton,
    TimelineList,
    TimelineItem,
    SimpleTypeahead
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      task: null, // Obiekt wybranego zadania

      users: [], // Tablica wszystkich użytkowników pobierana z API
      usersAC: [], // Tablica imion i nazwisk wszystkich użytkowników pobierana z API

      customers: [], // Tablica wszystkich kontrahentów pobierana z API
      customersAC: [], // Tablica nazw wszystkich kontrahentów pobierana z API

      comment: "", // Zmienna zawierająca treść komentarza
      order: false, // Filtr aktywności

      group: null, // Obiekt zawierający dane dotyczące grupy
      
      groupMembers: [], // Tablica dostępnych członków grupy
      groupMembersAC: [], // Tablica imion i nazwisk dostępnych członków grupy

      tasks: [], // Tablica zawierająca dane zadań z API
      tasksAC: [], // Tablica zawierająca nazwy zadań z API

      groups: [], // Tablica grup użytkownika
      members: [], // Tablica osób odpowiedzialnych pobierana z API

      modals: [], // Tablica odpowiedzialna za status modali

      createdCustomer: null, // Obiekt tworzonego kontrahenta

      subtask: null, // Obiekt tworzonego podzadania
      subtaskUsers: [], // Tablica wszystkich użytkowników pobierana z API
      subtaskUsersAC: [], // Tablica imion i nazwisk wszystkich użytkowników pobierana z API

      error: "", // Zmienna przechowująca treść błędu
      
      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona
      
      column: "closedAt", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      filter_name: "", // Filtr: Nazwa
      filter_groups: [], // Filtr: Grupa
      filter_sharedGroups: [], // Filtr: Współdzielona grupa
      filter_members: [], // Filtr: Osoba odpowiedzialna
      filter_status: ["todo", "late"], // Filtr: Status
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      this.getTasksDelay(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)
        
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_TASKS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.filter_members = [res.data.fullName]

          this.getTasks(this.page)
          this.getTasksAC(this.page)
          this.getGroups()
          this.getMembers()

          if (this.$store.state.openedTask) {
            this.getTask(this.$store.state.openedTask)
            this.$store.state.openedTask = null
          }

          if (this.$store.state.createdTask.operation) {
            this.getNewTask(this.$store.state.createdTask.attachments, this.$store.state.createdTask.sale, this.$store.state.createdTask.event)
            this.$store.state.createdTask.sale = null
            this.$store.state.createdTask.event = null
            this.$store.state.createdTask.operation = false
            this.$store.state.createdTask.attachments = []
          }
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje obiekt wybranego zadania
    getTask(id, operation = "overview") {
      TaskDataService.get(id)
      .then(res => {
        this.task = res.data
        this.task.operation = operation
        this.task.activity = "history"

        if (operation === "edit") {
          this.task.group = this.task.group.id
        }

        this.getUsers()
        this.getCustomers()

        this.getTaskHistory(res.data.id)
        this.getTaskComments(res.data.id)

        this.task.newInputs = []
        this.task.removedInputs = []
        this.task.newAttachments = []
        this.task.removedAttachments = []
        
        this.error = ""
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca obiekt nowego zadania
    getNewTask(attachments = [], sale = null, event = null) {
      this.task = null
      this.task = {
        name: "",
        closedAt: "",
        status: "todo",
        member: this.me,
        customer: null,
        group: "",
        note: "",
        inputs: [],
        attachments: attachments,
        sale: sale,
        event: event,
        operation: "new",
      }

      this.getUsers()
      this.getCustomers()

      this.error = ""
    },

    // Funkcja pobierająca wszystkich użytkowników z API
    getUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.users = []
        this.usersAC = []

        res.data["hydra:member"].forEach(item => {
          if (this.task.member && item.id !== this.task.member.id) {
            this.users.push(item)
            this.usersAC.push(item.fullName)
          }
          
          if (!this.task.member) {
            this.users.push(item)
            this.usersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkich kontrahentów z API
    getCustomers() {
      CustomerDataService.getAll("")
      .then(res => {
        this.customers = []
        this.customersAC = []

        res.data["hydra:member"].forEach(item => {
          if (this.task.customer && item.id !== this.task.customer.id) {
            this.customers.push(item)
            this.customersAC.push(item.displayName)
          }
          
          if (!this.task.customer) {
            this.customers.push(item)
            this.customersAC.push(item.displayName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca poprawność wprowadzonych danych
    validationPostTask() {
      let success = true
      this.error = ""

      if (this.task.name === "") {
        success = false
        this.error = "Nazwa zadania nie może być pusta!"
      }

      if (this.task.name.length > 65) {
        success = false
        this.error = "Nazwa zadania jest za długa (max. 65 znaków)"
      }

      if (this.task.closedAt === "") {
        success = false
        this.error = "Wybierz termin zadania!"
      }

      if (this.task.member === null) {
        success = false
        this.error = "Wybierz osobę odpowiedzialną!"
      }

      if (this.task.group === "") {
        success = false
        this.error = "Wybierz grupę!"
      }

      this.task.inputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }
      })

      if (success) {
        this.postTask()
      }
    },

    // Funkcja dodająca nowe zadanie do API
    postTask() {
      TaskDataService.post(this.task)
      .then(res => {
        console.log(res.data)

        this.task.attachments.forEach(item => {
          this.postAttachment(res.data.id, item)
        })

        this.getTasks(this.page)
        this.getTasksAC()
        this.task = null
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca poprawność wprowadzonych danych
    validationPatchTask() {
      let success = true
      this.error = ""

      if (this.task.name === "") {
        success = false
        this.error = "Nazwa zadania nie może być pusta!"
      }

      if (this.task.name.length > 65) {
        success = false
        this.error = "Nazwa zadania jest za długa (max. 65 znaków)"
      }

      if (this.task.closedAt === "") {
        success = false
        this.error = "Wybierz termin zadania!"
      }

      if (this.task.member === null) {
        success = false
        this.error = "Wybierz osobę odpowiedzialną!"
      }

      if (this.task.group === "") {
        success = false
        this.error = "Wybierz grupę!"
      }

      this.task.inputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }
      })

      this.task.newInputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }
      })

      if (success) {
        this.patchTask()
      }
    },

    // Funkcja edytująca wybrane zadanie
    patchTask() {
      TaskDataService.patch(this.task.id, this.task,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)

        this.task.newAttachments.forEach(item => {
          this.postAttachment(this.task.id, item)
        })

        this.snackbar = "success"

        this.getTasks(this.page)
        this.getTasksAC()
        this.task = null
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wybraną grupę
    getTaskGroup(id) {
      TaskGroupDataService.get(id)
      .then(res => {
        this.group = res.data
        this.group.error = ""
        this.getGroupMembers()
        this.openModal(1)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca obiekt nowej grupy
    getNewTaskGroup() {
      this.group = {
        name: "",
        error: "",
      }

      this.openModal(0)
    },

    // Funkcja dodająca nową grupę zadań
    postTaskGroup() {
      if (this.group.name !== "") {
        TaskGroupDataService.post(
          {
            name: this.group.name,
            color: "#e91e63",
            owner: `/users/${ this.me.id }`,
            members: [`/users/${ this.me.id }`]
          }
        )
        .then(res => {
          console.log(res.data)
          this.getGroups()
          this.closeModal()
        })
        .catch(error => {
          console.log(error)

          if(JSON.stringify(error.response.status) == 422) {
            this.group.error = "Nazwa grupy jest już zajęta!"
          }
        })
      }
      else {
        this.group.error = "Nazwa grupy nie może być pusta!"
      }
    },

    // Funkcja edytująca wybraną grupę
    patchTaskGroup(id) {
      if (this.group.name !== "") {
        let members = []
        this.group.members.forEach(item => {
          members.push(`/users/${ item.id }`)
        })

        TaskGroupDataService.patch(id,
          {
            name: this.group.name,
            members: members
          },
          {
            headers: { "Content-Type": "application/merge-patch+json" }
          }
        )
        .then(res => {
          console.log(res.data)
          this.getGroups()
          this.closeModal()
        })
        .catch(error => {
          console.log(error)

          if(JSON.stringify(error.response.status) == 422) {
            this.group.error = "Nazwa grupy jest już zajęta!"
          }
        })
      }
      else {
        this.group.error = "Nazwa grupy nie może być pusta!"
      }
    },

    // Funkcja pobierająca dostepnych członków grupy
    getGroupMembers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.groupMembers = []
        this.groupMembersAC = []

        res.data["hydra:member"].forEach(item => {
          let add = true

          this.group.members.forEach(member => {
            if (item.id === member.id) {
              add = false
            }
          })

          if (add) {
            this.groupMembers.push(item)
            this.groupMembersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybraną grupę
    deleteGroup(id) {
      TaskGroupDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getGroups()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca status zadania
    onChangeTaskStatus(id) {
      TaskDataService.patch(id,
        {
          id: id,
          status: "done"
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getTasks(this.page)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca status wszystkich zadań
    onChangeTasksStatus() {
      this.tasks.forEach(item => {
        if (item.status !== "done") {
          this.onChangeTaskStatus(item.id)
        }
      })
    },

    // Funkcja zmieniająca status podzadania
    onChangeSubtaskStatus(id, event) {
      SubtaskDataService.patch(id,
        {
          status: event ? "done" : "todo",
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getTask(this.task.id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca załącznik do listy
    createAttachment(array, event) {
      array.push(
        {
          file: event.target.files[0],
          description: ""
        }
      )
    },

    // Funkcja tworząca nowy załącznik
    postAttachment(id, item) {
      const formData = new FormData()
      formData.append("file", item.file)
      formData.append("description", item.description)
      formData.append("entity", "Task")
      formData.append("entityId", id)

      AttachmentDataService.post(formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja znajdująca najbliższy piątek
    findNextFriday() {
      let today = this.moment()
      while (today.day() != 5) {
        today.add(1, "days")
      }

      return today.format("yyyy-MM-DD")
    },

    // Funkcja zwracająca ile czasu pozostało do zadania
    getRelativeTime(date) {
      const now = this.moment()
      const targetDate = this.moment(date)

      if (now.isSame(targetDate, "day")) {
        return "dzisiaj"
      }
      else if (now.clone().add(1, "day").isSame(targetDate, "day")) {
        return "jutro"
      }
      else if (now.clone().subtract(1, "day").isSame(targetDate, "day")) {
        return "wczoraj"
      }
      else {
        return targetDate.fromNow()
      }
    },

    // Funkcja tworząca nowego kontrahenta
    getNewCustomer() {
      CustomerGroupDataService.getAll("")
      .then(res => {
        this.createdCustomer = {
          type: "Company",
          displayName: "",
          groups: [],
          error: "",
        }

        this.createdCustomer.groups = res.data["hydra:member"]
        this.createdCustomer.groups.forEach(item => {
          item.status = false
        })

        this.openModal(2)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja dodająca do API nowego kotnrahenta
    postNewCustomer() {
      this.createdCustomer.error = ""
      if (this.createdCustomer.displayName !== "") {
        if (this.createdCustomer.displayName.length <= 65) {
          if (this.createdCustomer.groups.some(group => group.status === true)) {
            CustomerDataService.post(this.createdCustomer)
            .then(res => {
              if (this.task.customer) {
                this.customers.push(this.task.customer)
                this.customersAC.push(this.task.customer.displayName) 
              }

              this.task.customer = res.data
              this.closeModal()
            })
            .catch(error => {
              console.log(error)

              if (JSON.stringify(error.response.status) == 400) {
                this.createdCustomer.error = error.response.data.error
              }
            })
          }
          else {
            this.createdCustomer.error = "Kontrahent musi należeć do min. 1 grupy"
          }
        }
        else {
          this.createdCustomer.error = "Nazwa kontrahenta jest za długa (max. 65 znaków)"
        }
      }
      else {
        this.createdCustomer.error = "Podaj nazwę kontrahenta!"
      }
    },

    // Funkcja pobierająca wybrane podzadanie
    getSubtask(id) {
      SubtaskDataService.get(id)
      .then(res => {
        this.subtask = res.data
        this.subtask.error = ""
        this.getSubtaskUsers()
        this.openModal(4)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowe podzadanie
    getNewSubtask(id) {
      this.subtask = {
        name: "",
        status: "todo",
        member: null,
        task: `/tasks/${ id }`,
        error: "",
      }

      this.getSubtaskUsers()
      this.openModal(3)
    },

    // Funkcja pobierająca wszystkich użytkowników z API
    getSubtaskUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.subtaskUsers = []
        this.subtaskUsersAC = []

        res.data["hydra:member"].forEach(item => {
          if (this.subtask.member && item.id !== this.subtask.member.id) {
            this.subtaskUsers.push(item)
            this.subtaskUsersAC.push(item.fullName)
          }
          
          if (!this.subtask.member) {
            this.subtaskUsers.push(item)
            this.subtaskUsersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca wybranego użytkownika do podzadania
    selectSubtaskUser(item) {
      this.subtaskUsers.forEach((user, index) => {
        if (item === user.fullName) {
          if (this.subtask.member) {
            this.subtaskUsers.push(this.subtask.member)
            this.subtaskUsersAC.push(this.subtask.member.fullName) 
          }

          this.subtask.member = user
          this.subtaskUsers.splice(index, 1)
          this.subtaskUsersAC.splice(index, 1)
        }
      })

      if (this.$refs.usersRef) {
        this.$refs.usersRef.clearInput()
      }
    },

    // Funkcja dodająca do API nowe podzadanie
    postSubtask() {
      this.subtask.error = ""
      if (this.subtask.name !== "") {
        if (this.subtask.name.length <= 65) {
          SubtaskDataService.post({
            name: this.subtask.name,
            status: this.subtask.status,
            member: this.subtask.member ? `/users/${ this.subtask.member.id }` : null,
            task: this.subtask.task,
          })
          .then(res => {
            this.task.subtasks.push(res.data)
            this.getTasks(this.page)
            this.closeModal()
          })
          .catch(error => {
            console.log(error)

            if (JSON.stringify(error.response.status) == 400) {
              this.subtask.error = error.response.data.error
            }
          })
        }
        else {
          this.subtask.error = "Nazwa podzadania jest za długa (max. 65 znaków)"
        }
      }
      else {
        this.subtask.error = "Podaj nazwę podzadania!"
      }
    },

    // Funkcja aktualizująca wybrane podzadanie w API
    patchSubtask() {
      this.subtask.error = ""
      if (this.subtask.name !== "") {
        if (this.subtask.name.length <= 65) {
          SubtaskDataService.patch(this.subtask.id,
            {
              name: this.subtask.name,
              status: this.subtask.status,
              member: this.subtask.member ? `/users/${ this.subtask.member.id }` : null,
              task: this.subtask.task,
            },
            {
              headers: { "Content-Type": "application/merge-patch+json" }
            }
          )
          .then(res => {
            console.log(res.data)
            this.getTask(this.task.id)
            this.getTasks(this.page)
            this.closeModal()
          })
          .catch(error => {
            console.log(error)

            if (JSON.stringify(error.response.status) == 400) {
              this.subtask.error = error.response.data.error
            }
          })
        }
        else {
          this.subtask.error = "Nazwa podzadania jest za długa (max. 65 znaków)"
        }
      }
      else {
        this.subtask.error = "Podaj nazwę podzadania!"
      }
    },

    // Funkcja pobierająca historie wybranego zadania
    getTaskHistory(id) {
      let value = this.order ? "asc" : "desc"
      HistoryDataService.getAll(`?page=1&itemsPerPage=10&order[createdAt]=${ value }&task.id=${ id }`)
      .then(res => {
        this.task.histories = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca historie do wybranego zadania
    postTaskHistory(id, subject, text) {
      HistoryDataService.post(
        {
          owner: `/users/${ this.me.id }`,
          subject: subject,
          text: text,
          createdAt: this.moment().format(),
          task: `/tasks/${ id }`
        }
      )
      .then(res => {
        console.log(res.data)
        this.getTaskHistory(id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca komentarze wybranego zadania
    getTaskComments(id) {
      let value = this.order ? "asc" : "desc"
      CommentDataService.getAll(`?task.id=${ id }&order[createdAt]=${ value }`)
      .then(res => {
        this.task.comments = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca komentarz
    postTaskComment(id) {
      if (this.comment !== "") {
        CommentDataService.post(
          {
            owner: `/users/${ this.me.id }`,
            text: this.comment,
            createdAt: this.moment().format(),
            task: `/tasks/${ id }`
          }
        )
        .then(res => {
          console.log(res.data)
          this.postTaskHistory(id, "dodał/a komentarz", res.data.text)
          this.getTaskComments(id)
          this.comment = ""
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // Funkcja usuwająca komentarz
    deleteTaskComment(id) {
      CommentDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getTaskComments(this.task.id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrane zadania
    deleteTask(id) {
      TaskDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getTasks(this.page)
        this.getTasksAC()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca wybranego użytkownika do zadania
    selectUser(item) {
      this.users.forEach((user, index) => {
        if (item === user.fullName) {
          if (this.task.member) {
            this.users.push(this.task.member)
            this.usersAC.push(this.task.member.fullName) 
          }

          this.task.member = user
          this.users.splice(index, 1)
          this.usersAC.splice(index, 1)
        }
      })

      if (this.$refs.usersRef) {
        this.$refs.usersRef.clearInput()
      }
    },

    // Funkcja przypisująca wybranego kontrahenta do zadania
    selectCustomer(item) {
      this.customers.forEach((customer, index) => {
        if (item === customer.displayName) {
          if (this.task.customer) {
            this.customers.push(this.task.customer)
            this.customersAC.push(this.task.customer.displayName) 
          }

          this.task.customer = customer
          this.customers.splice(index, 1)
          this.customersAC.splice(index, 1)
        }
      })

      this.$refs.customersRef.clearInput()
    },

    // Funkcja dodająca wybranego użytkownika do grupy
    selectMember(item) {
      this.groupMembers.forEach((member, index) => {
        if (item === member.fullName) {
          this.group.members.push(member)
          this.groupMembers.splice(index, 1)
          this.groupMembersAC.splice(index, 1)
        }
      })

      this.$refs.membersRef.clearInput()
    },

    // Funkcja pobierająca zadania z API
    getTasks(page) {
      if (this.me.taskGroups.length > 0) {
        let groups = ""
        if (this.filter_groups.length === 0 && this.filter_sharedGroups.length === 0) {
          this.me.taskGroups.forEach(item => {
            groups += `&group.name[]=${ item.name }`
          })
        }
        else {
          if (this.filter_groups.length > 0) {
            this.filter_groups.forEach(item => {
              groups += `&group.name=${ item }`
            })
          }
          
          if (this.filter_sharedGroups.length > 0) {
            this.filter_sharedGroups.forEach(item => {
              groups += `&group.name=${ item }`
            })
          }
        }

        let member = ""
        this.filter_members.forEach(item => {
          member += `&member.fullName=${ item }&subtasks.member.fullName=${ item }`
        })

        let status = ""
        this.filter_status.forEach(item => {
          status += `&status[]=${ item }`
        })

        TaskDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ groups }${ member }${ status }`)
        .then(res => {
          this.page = page
          this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
          this.tasks = res.data["hydra:member"]
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // Funkcja pobierająca zadania z API z opóźnieniem
    getTasksDelay: _.debounce(function(page) {
      if (this.me.taskGroups.length > 0) {
        let groups = ""
        if (this.filter_groups.length === 0 && this.filter_sharedGroups.length === 0) {
          this.me.taskGroups.forEach(item => {
            groups += `&group.name[]=${ item.name }`
          })
        }
        else {
          if (this.filter_groups.length > 0) {
            this.filter_groups.forEach(item => {
              groups += `&group.name=${ item }`
            })
          }
          
          if (this.filter_sharedGroups.length > 0) {
            this.filter_sharedGroups.forEach(item => {
              groups += `&group.name=${ item }`
            })
          }
        }

        let member = ""
        this.filter_members.forEach(item => {
          member += `&member.fullName=${ item }&subtasks.member.fullName=${ item }`
        })

        let status = ""
        this.filter_status.forEach(item => {
          status += `&status[]=${ item }`
        })

        TaskDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ groups }${ member }${ status }`)
        .then(res => {
          this.page = page
          this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
          this.tasks = res.data["hydra:member"]
        })
        .catch(error => {
          console.log(error)
        })
      }
    }, 500),

    // Funkcja pobierająca nazwy zadań z API
    getTasksAC() {
      TaskDataService.getAll("")
      .then(res => {
        this.tasksAC = []
        res.data["hydra:member"].forEach(item => {
          this.tasksAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca grupy użytkownika
    getGroups() {
      TaskGroupDataService.getAll(`?members.id=${ this.me.id }`)
      .then(res => {
        this.groups = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkich użytkowników z API
    getMembers() {
      UserDataService.getAll("?order[fullName]=ASC")
      .then(res => {
        this.members = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_name = item
      this.getTasks(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeGroup(event) {
      let exist = false

      this.filter_groups.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_groups.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_groups.push(event)
      }

      this.getTasks(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeSharedGroup(event) {
      let exist = false

      this.filter_sharedGroups.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_sharedGroups.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_sharedGroups.push(event)
      }

      this.getTasks(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeMember(event) {
      let exist = false

      this.filter_members.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_members.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_members.push(event)
      }

      this.getTasks(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeStatus(event) {
      let exist = false

      this.filter_status.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_status.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_status.push(event)
      }

      this.getTasks(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      this.getTasks(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getTasks(page)
      }
      else {
        this.getTasks(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getTasks(page)
      }
      else {
        this.getTasks(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getTasks(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getTasks(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>